.homepage-kld0203 {
    .section-1-kld0203 {
        width: 100%;
        height: 100vh;
        transition: all 1.4s;
        img {
            animation: fadeInSlide 0.2s ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
        }
        .introduce {
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.305);
            color: white;
            position: absolute;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 150px;
            .bx-introduce {
                padding-top: 160px;
                width: 1400px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .title {
                    font-size: 3rem;
                    letter-spacing: 1px;
                    font-weight: 600;
                    padding: 20px 0;
                    text-transform: uppercase;
                }
                .sub-title {
                    font-size: 2.5rem;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
                .bx-srv {
                    display: flex;
                    .bxf {
                        padding: 20px;
                        flex: 1; /* Giúp các mục có cùng kích thước */
                        display: flex;
                        align-items: stretch;
                        .pd {
                            display: flex;
                            gap: 20px;
                            align-items: center;

                            padding: 30px;
                            border-radius: 15px;
                            background-color: rgba(35, 35, 35, 0.715);
                            i {
                                font-size: 36px;
                                color: #ff9900;
                            }
                            .bx {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                .abv {
                                    color: #ff9900;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                    font-size: 1.2rem;
                                    letter-spacing: 1px;
                                }
                                .blw {
                                    letter-spacing: 1px;
                                }
                            }
                        }
                    }
                }
                .btn-intro {
                    padding: 20px 0;
                    display: flex;
                    gap: 20px;
                    letter-spacing: 1px;
                    .btn-ele {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.2rem;
                        text-decoration: none;
                        border-radius: 15px;
                        width: 200px;
                        background-color: black;
                        color: white;
                        transition:
                            box-shadow 0.2s ease-in-out,
                            color 0.5s ease-in-out;
                        text-align: center;
                        padding: 10px;
                        cursor: pointer;
                        text-transform: uppercase;
                        font-weight: 600;
                        i {
                            color: #ff9900;
                        }
                        &:hover {
                            color: #ff9900;
                            box-shadow: inset 0 0 1.5em 1.5em #efeeee;
                        }
                    }
                }
            }
        }
    }
    .section-2-kld0203 {
        width: 1400px;
        margin: 0 auto;
        padding-top: 40px;
        .title {
            text-align: center;
            font-size: 1.5rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: bold;
        }
        .price-table-container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 20px;
        }

        .price-table {
            border: 1px solid rgba(0, 0, 0, 0.721);
            border-radius: 10px; /* Bo tròn bảng */
            overflow: hidden; /* Đảm bảo bo tròn hoạt động */
        }

        .price-table th,
        .price-table td {
            border: 1px solid #00000038;
            padding: 10px 20px;
            text-align: left;
        }

        .price-table th {
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            font-size: 1.4rem;
        }

        .category-cell {
            font-weight: bold;
            text-align: center;
            font-size: 1.2rem;
        }

        .price {
            font-size: 1.1rem;
            letter-spacing: 1px;
            color: #ff9900;
            font-weight: bold;
        }
        .price-table tbody tr:nth-child(odd) {
            background-color: #f9f9f9; /* Màu trắng nhạt */
        }

        .price-table tbody tr:nth-child(even) {
            background-color: #eeeeee; /* Màu xám nhạt */
        }
        .btn-intro {
            padding: 30px 0;
            display: flex;
            gap: 20px;
            letter-spacing: 1px;
            width: 1400px;
            justify-content: center;
            .btn-ele {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;
                font-size: 1.2rem;
                text-decoration: none;
                border-radius: 15px;
                width: 200px;
                background-color: black;
                color: white;
                transition:
                    box-shadow 0.2s ease-in-out,
                    color 0.5s ease-in-out;
                text-align: center;
                padding: 10px;
                cursor: pointer;
                text-transform: uppercase;
                font-weight: 600;
                i {
                    color: #ff9900;
                }
                &:hover {
                    color: #ff9900;
                    box-shadow: inset 0 0 1.5em 1.5em #efeeee;
                }
            }
        }
    }
    .section-3-kld0203 {
        width: 1400px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: 50px 0;
        .left {
            img {
                border-radius: 15px;
                width: 100%;
                object-fit: cover;
            }
        }
        .right {
            .bx {
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                .sub-title {
                    font-size: 16px;
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
                .title {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #ff9900;
                    text-transform: uppercase;
                }
                .content {
                    letter-spacing: 1px;
                }
            }
        }
    }
    .section-4-kld0203 {
        width: 1400px;
        margin: 0 auto;
        padding: 50px 0;
        .title {
            text-align: center;
            font-size: 1.5rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: bold;
            padding: 30px 0;
        }
        .bxw {
            display: flex;
            color: white;
            .bxc {
                padding: 20px;
                flex: 1; /* Giúp các mục có cùng kích thước */
                display: flex;
                align-items: stretch;
                .pd {
                    background-color: rgba(35, 35, 35, 0.523);
                    border-radius: 15px;
                    padding: 20px 10px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    cursor: pointer;
                    transition: all 0.5s;
                    &:hover {
                        transform: translateY(-10px);
                    }
                    .title-bx {
                        border-bottom: 1px solid white;
                        font-weight: bold;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        font-size: 1.4rem;
                        padding: 10px;
                    }
                    .content {
                        letter-spacing: 1px;
                        padding: 10px;
                    }
                }
            }
        }
    }
    .section-5-kld0203 {
        width: 1400px;
        margin: 0 auto;
        padding: 0 0 50px 0;
        .title {
            text-align: center;
            font-size: 1.5rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: bold;
            padding: 30px 0;
        }
        .bxw {
            display: flex;
            flex-wrap: wrap;

            .bxc {
                padding: 20px;
                display: flex;
                align-items: stretch;
                .pd {
                    border-radius: 15px;
                    border: 1px solid rgba(128, 128, 128, 0.313);
                    border-radius: 15px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    cursor: pointer;
                    transition: all 0.5s;
                    &:hover {
                        transform: translateY(-10px);
                    }
                    img {
                        width: 100%;
                        object-fit: cover;
                        border-radius: 15px 15px 0 0;
                    }
                    .title-bx {
                        color: #ff9900;
                        font-weight: bold;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        font-size: 1.3rem;
                        padding: 10px 10px 0 10px;
                    }
                    .content {
                        letter-spacing: 1px;
                        padding: 10px 10px 20px 10px;
                    }
                }
            }
        }
        .btn-intro {
            padding: 30px 0;
            display: flex;
            gap: 20px;
            letter-spacing: 1px;
            width: 1400px;
            justify-content: center;
            .btn-ele {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;
                font-size: 1.2rem;
                text-decoration: none;
                border-radius: 15px;
                width: 200px;
                background-color: black;
                color: white;
                transition:
                    box-shadow 0.2s ease-in-out,
                    color 0.5s ease-in-out;
                text-align: center;
                padding: 10px;
                cursor: pointer;
                text-transform: uppercase;
                font-weight: 600;
                i {
                    color: #ff9900;
                }
                &:hover {
                    color: #ff9900;
                    box-shadow: inset 0 0 1.5em 1.5em #efeeee;
                }
            }
        }
    }
    .section-6-kld0203 {
        width: 1400px;
        margin: 0 auto;
        padding: 0 0 50px 0;
        .title {
            text-align: center;
            font-size: 1.5rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: bold;
            padding: 30px 0 10px 0;
        }
        .sub-title {
            text-align: center;
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: bold;
            color: grey;
            padding: 0 0 30px 0;
        }
        .bx-srv {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .bxf {
                display: flex;
                align-items: stretch;
                padding: 20px;
                border-radius: 15px;
                .pd {
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    width: 100%;
                    padding: 30px;
                    background-color: rgba(71, 71, 71, 0.641);
                    border-radius: 15px;
                    cursor: pointer;
                    transition: all 0.5s;
                    &:hover {
                        transform: translateY(-10px);
                    }
                    i {
                        font-size: 36px;
                        color: #ff9900;
                        padding: 20px 30px;
                        border-radius: 50%;
                        background-color: white;
                    }
                    .bx {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        .abv {
                            color: #ff9900;
                            font-weight: bold;
                            text-transform: uppercase;
                            font-size: 1.2rem;
                            letter-spacing: 1px;
                        }
                        .blw {
                            letter-spacing: 1px;
                            color: white;
                        }
                    }
                }
            }
        }
        .btn-intro {
            padding: 30px 0;
            display: flex;
            gap: 20px;
            letter-spacing: 1px;
            width: 1400px;
            justify-content: center;
            .btn-ele {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: center;
                font-size: 1.2rem;
                text-decoration: none;
                border-radius: 15px;
                width: 200px;
                background-color: black;
                color: white;
                transition:
                    box-shadow 0.2s ease-in-out,
                    color 0.5s ease-in-out;
                text-align: center;
                padding: 10px;
                cursor: pointer;
                text-transform: uppercase;
                font-weight: 600;
                i {
                    color: #ff9900;
                }
                &:hover {
                    color: #ff9900;
                    box-shadow: inset 0 0 1.5em 1.5em #efeeee;
                }
            }
        }
    }
    .section-7-kld0203 {
        width: 1400px;
        margin: 0 auto;
        padding: 0 0 50px 0;
        .title {
            text-align: center;
            font-size: 1.5rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: bold;
            padding: 30px 0;
        }
        .bxw {
            display: flex;
            .bxc {
                padding: 20px;
                flex: 1; /* Giúp các mục có cùng kích thước */
                display: flex;
                align-items: stretch;
                .pd {
                    padding: 20px 10px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    cursor: pointer;
                    transition: all 0.5s;
                    &:hover {
                        transform: translateY(-10px);
                    }
                    i {
                        font-size: 4.5rem;
                        color: #ff9900;
                    }
                    .title-bx {
                        color: #ff9900;
                        font-weight: bold;
                        letter-spacing: 1px;
                    }
                    .content {
                        letter-spacing: 1px;
                        padding: 10px;
                    }
                }
            }
        }
    }
    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(100px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .animate-kassdev {
        opacity: 0; /* Ẩn trước khi trigger */
    }

    .animate {
        animation: fadeInUp 1s ease forwards;
    }

    .delay-1s {
        animation-delay: 0.2s;
    }
    .delay-2s {
        animation-delay: 0.4s;
    }
    .delay-3s {
        animation-delay: 0.6s;
    }
    .delay-4s {
        animation-delay: 0.8s;
    }
    .delay-5s {
        animation-delay: 1s;
    }
    .delay-6s {
        animation-delay: 1.2s;
    }
    .delay-7s {
        animation-delay: 1.4s;
    }
}

@media (max-width: 950px) {
    .homepage-kld0203 {
        .section-1-kld0203 {
            width: 100%;
            height: 850px !important;
            img {
                height: 850px !important;
            }
            .introduce {
                height: 850px !important;
                padding: 10px;
                .bx-introduce {
                    width: 100%;
                    flex-direction: column;
                    justify-content: flex-end;
                    padding-bottom: 20px;
                    .title {
                        font-size: 1.5rem;
                    }
                    .sub-title {
                        font-size: 1.2rem;
                    }
                    .bx-srv {
                        flex-direction: column;
                        .bxf {
                            .pd {
                                width: 100%;
                                padding: 15px 20px;
                                i {
                                    font-size: 26px;
                                }
                                .bx {
                                    .abv {
                                        font-size: 1rem;
                                    }
                                    .blw {
                                        font-size: 0.8rem;
                                    }
                                }
                            }
                        }
                    }
                    .btn-intro {
                        width: 100%;
                        padding: 0 20px;
                        .btn-ele {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
        .section-2-kld0203 {
            width: 100%;
            .title {
                font-size: 1.2rem;
                padding: 0 10px;
            }
            .price-table-container {
                width: 100%;
                padding: 20px;
            }
            .price-table th {
                font-size: 1rem;
            }
            .category-cell {
                font-size: 1rem;
            }
            .price-table th,
            .price-table td {
                padding: 10px;
                font-size: 0.8rem;
            }
            .price {
                font-size: 0.8rem;
            }
            .btn-intro {
                width: 100%;
                padding: 10px 20px;
                .btn-ele {
                    font-size: 1rem;
                }
            }
        }
        .section-3-kld0203 {
            width: 100%;
            flex-direction: column;
            padding-bottom: 0;
            .left {
                padding: 15px;
            }
            .right {
                .bx {
                    .sub-title {
                        font-size: 0.9rem;
                    }
                    .title {
                        font-size: 1.1rem;
                    }
                    .content {
                        font-size: 0.9rem;
                    }
                }
            }
        }
        .section-4-kld0203 {
            width: 100%;

            .bx {
                .bxw {
                    flex-direction: column;
                    .bxc {
                        .pd {
                            .title-bx {
                                font-size: 1.1rem;
                            }
                            .content {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
                .title {
                    font-size: 1.2rem;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
        .section-5-kld0203 {
            width: 100%;
            .bx {
                .title {
                    font-size: 1.2rem;
                    padding-left: 10px;
                    padding-right: 10px;
                }
                .bxw {
                    .bxc {
                        .pd {
                            .title-bx {
                                font-size: 1.1rem;
                            }
                            .content {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
                .btn-intro {
                    width: 100%;
                    padding: 10px 20px;
                    .btn-ele {
                        font-size: 1rem;
                    }
                }
            }
        }
        .section-6-kld0203 {
            width: 100%;
            .bx {
                .title {
                    font-size: 1.2rem;
                    padding-left: 10px;
                    padding-right: 10px;
                }
                .sub-title {
                    font-size: 0.9rem;
                    padding-left: 10px;
                    padding-right: 10px;
                }
                .btn-intro {
                    width: 100%;
                    padding: 10px 20px;
                    .btn-ele {
                        font-size: 1rem;
                    }
                }
                .bxf {
                    .pd {
                        flex-direction: column;
                        justify-content: center;
                        i {
                            order: 1;
                        }
                        .bx {
                            order: 2;
                            .abv {
                                font-size: 1.1rem;
                            }
                            .blw {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
            }
        }
        .section-7-kld0203 {
            width: 100%;
            .bx {
                .title {
                    font-size: 1.2rem;
                    padding-left: 10px;
                    padding-right: 10px;
                }
                .bxw {
                    flex-direction: column;
                    .bxc {
                        .pd {
                            .content {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
            }
        }
        .section-8-kld0203 {
            width: 100%;
        }
    }
    .delay-6s {
        animation-delay: 0.2s;
    }
    .delay-7s {
        animation-delay: 0.4s;
    }
}
