.hotline-icon-one-pc {
    z-index: 999;
    width: max-content;
    background: rgba(0, 0, 0, 0.542);
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite;

    position: fixed;
    bottom: 40px;
    right: 30px;
}
.hotline-icon-svg {
    width: 40px;
}
.hotline-icon-one-ios {
    z-index: 999;
    width: max-content;
    background: rgba(0, 0, 0, 0.542);
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite;
    position: fixed;
    bottom: 40px;
    right: 30px;
    display: none;
}
.hotline-icon-one-android {
    z-index: 999;
    width: max-content;
    background: rgba(0, 0, 0, 0.542);
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite;
    position: fixed;
    bottom: 40px;
    right: 30px;
    display: none;
}
.hotline-icon-two {
    z-index: 999;
    width: max-content;
    background: rgba(0, 0, 0, 0.542);
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite;
    position: fixed;
    bottom: 140px;
    right: 30px;
}
.hotline-icon-three {
    z-index: 999;
    width: max-content;
    background: rgb(255 255 255);
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite;
    position: fixed;
    bottom: 190px;
    left: 20px;
    right: 0;
}

.header-sell-website {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 98;
    background: #ffffffd9;
    box-shadow: -1px 3px 18px #888888;
    transition: all 0.5s;
    /* background: linear-gradient(to right, #a8c6f1f7, #edf4fee5); */
}

.navbar-sell-website {
    display: flex;
    align-items: center;
    max-width: 1360px;
    margin: 0 auto;
    justify-content: space-between;
    i {
        display: none;
    }
    .logo {
        order: 1;
        gap: 10px;
        display: flex;
        align-items: center;
        text-decoration: none;
    }
    .logo-res {
        order: 1;
        gap: 10px;
        display: flex;
        align-items: center;
        text-decoration: none;
        display: none;
        img {
            width: 60px;
        }
    }
    .logo {
        padding: 10px 0;
        img {
            width: 75px;
            height: 75px;
            object-fit: cover;
        }
        .name {
            .abv {
                font-weight: bold;
                color: black;
                text-transform: uppercase;
            }
            .content {
                text-transform: uppercase;
                font-size: 13px;
                color: grey;
            }
        }
    }
    .links {
        order: 2;
        display: flex;
        gap: 35px;
        margin: 0;
        list-style: none;
        align-items: center;
        text-transform: uppercase;
        font-size: 1.5rem;
    }
    .linksf {
        display: none;
        order: 2;
        .links {
            display: flex;
            gap: 35px;
            margin: 0;
            list-style: none;
            align-items: center;
            text-transform: uppercase;
            font-size: 1.5rem;
        }
    }
    .login-btn {
        order: 3;
        border: none;
        outline: none;
        background: #151515;
        color: white;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 600;
        padding: 10px 18px;
        border-radius: 15px;
        cursor: pointer;
        opacity: 1;
        height: 50px; /* Điều chỉnh chiều cao tối đa phù hợp */
        transition:
            opacity 0.2s ease,
            height 0.2s ease;
        &:hover {
            background: #151515a6;
        }
    }
}
.section-nav-mobile-box {
    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    height: 100vh;
    background: white;
    padding: 10px;

    .section-nav-mobile {
        padding: 5px 10px 10px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        letter-spacing: 1px;
        .active-fa-chevron-right {
            transition-duration: 0.2s;
            transform: rotate(90deg);
        }
        .deactivate-fa-chevron-right {
            transition-duration: 0.2s;
            transform: rotate(0deg);
        }
        a {
            text-decoration: none;
            color: black;
        }
        .image {
            width: 100%;
            display: flex;
            justify-content: center;
            border-bottom: 1px solid grey;
            img {
                width:70px;
                padding-bottom: 20px;
            }
        }
    }
    .sub-menu-kld0203 {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        opacity: 0;
        max-height: 0; /* Giới hạn chiều cao ban đầu */
        transition:
            max-height 0.5s ease,
            opacity 0.5s ease;
        
        li {
            list-style-type: none;
            padding: 10px;
            .open-sub {
                opacity: 1; /* Hiển thị khi mở */
                max-height: 500px; /* Chiều cao tối đa cho menu khi mở */
                overflow-y: auto;
            }
        }
        button,
        a {
            cursor: pointer;
            text-decoration: none;
            background-color: unset;
            color: black;
            border: none;
            padding: 0 10px;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }

    .open {
        opacity: 1; /* Hiển thị khi mở */
        max-height: 550px; /* Chiều cao tối đa cho menu khi mở */
        overflow-y: auto;
    }
}
.section-nav-mobile-box.active {
    display: none;
}
.section-nav-mobile-box.deactivate {
    display: none;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 98;
    transition: all 0.3s ease;
}

@media (max-width: 950px) {
    .hotline-icon-one-pc {
        display: flex;
    }
    .hotline-icon-one-ios {
        display: flex;
    }
    .hotline-icon-one-android {
        display: flex;
    }
    .header-sell-website {
        .navbar-sell-website {
            flex-direction: column;
            width: 100%;
            padding: 10px;

            .logo {
                flex: 1;
                padding: 0 20px;
                width: 100%;
                justify-content: space-around;
                i {
                    display: block;
                    color: black;
                    font-size: 24px;
                }
            }
            .links {
                padding: 10px 0;
                li {
                    padding: 0 10px;
                    font-size: 1.2rem;
                    text-align: center;
                }
            }
            .login-btn {
                font-size: 0.9rem;
            }
        }
    }
    .section-nav-mobile-box {
        display: inline-block;
    }
    .section-nav-mobile-box.active {
        display: inline-block !important;
        width: 60%;
        animation: onClick 0.2s forwards;
        @keyframes onClick {
            from {
                width: 0%;
            }
            to {
                width: 60%;
            }
        }
    }
    .scroll-hide {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition:
            opacity 0.3s ease-in-out,
            max-height 0.5s ease-in-out;
    }
}
